:root {
  --survey-back: #ffffff;
}

/* ----------- For survey -------------*/
.tableWith {
  width: 65%;
  border: 0;
}

.td1 {
  padding: 20px 10px 20px 10px;
  width: 170px;
}

.img1 {
  display: block;
  width: 500px;
  max-width: 500px;
  min-width: 40px;
  font-family: "Lato", Helvetica, Arial, sans-serif;
  color: #ffffff;
  font-size: 18px;
  max-height: 129px;
  object-fit: contain;
}

.mylogo {
  display: block;
  width: 500px;
  max-width: 400px;
  min-width: 40px;
  color: #ffffff;
  font-size: 18px;
  max-height: 129px;
  object-fit: contain;
  padding-top: 20px;
}

.squareImg {
  display: block;
  max-width: 200px;
  min-width: 40px;
  max-height: 200px;
}

.table2 {
  width: 65%;
  padding: 0px;
}

.td2 {
  padding: 0px 10px 0px 10px;
}

.tdTitle {
  padding: 40px 20px 20px 20px;
  border-radius: 5px 5px 0px 0px;
  color: #111111;
  font-family: "Lato", Helvetica, Arial, sans-serif;
  font-size: 48px;
  font-weight: 400;
  letter-spacing: 2px;
  line-height: 48px;
  background-color: var(--survey-back);
}

.tdTitle .sl-nav {
  letter-spacing: 0px;
  line-height: 0px;
  font-size: 18px;
  font-weight: 400;
  position: absolute;
  top: 11rem;
  right: 19%;
  color: #000;
}

@media screen and (max-width: 482px) {
  .tdTitle .sl-nav {
    top: 9rem;
    right: 5%;
  }
}

#surveyBackButton {
  position: absolute;
  left: 19%;
  top: 11rem;
  padding: 0 10px !important;
}

@media screen and (max-width: 482px) {
  #surveyBackButton {
    left: 5%;
    top: 8.8rem;
    padding: 0 10px !important;
  }
}

.tdTitle404 {
  padding: 40px 20px 20px 20px;
  border-radius: 7px 7px 0px 0px;
  color: #666666;
  font-family: Arial;
  font-size: 48px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 48px;
  background-color: #f4f4f4;
}

.tdTitle4Boa {
  padding: 40px 20px 20px 20px;
  border-radius: 7px 7px 0px 0px;
  color: #666666;
  font-family: Arial;
  font-size: 48px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 48px;
  background-color: #eddecc;
}

.Title {
  font-size: 38px;
  font-weight: 400;
  margin: 0;
}

.reduceTitle {
  font-size: 20px;
  font-weight: 400;
  margin: 0;
}

.tdBody {
  padding: 0px 10px 0px 10px;
}

.tdBody2 {
  padding: 20px 30px 40px 30px;
  color: #666666;
  font-family: "Lato", Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  border-radius: 0px 0px 7px 7px;
  background-color: var(--survey-back);
}
.tdBody3 {
  color: #4b4a4a;
  font-family: "Lato", Helvetica, Arial, sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 25px;
}

.tdBodyContact {
  padding: 20px 20px 20px 20px;
  color: #666666;
  font-family: "Lato", Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  border-radius: 0px 0px 7px 7px;
}

td#tdContact {
  margin: 0px 0px 0px 10px;
}
td#tdVideo {
  margin: 0px 10px 0px 0px;
}

.ContactFrom {
  padding: 20px 30px 40px 30px;
  color: #575757;
  background-color: white;
  line-height: 25px;
  border-radius: 0px 0px 7px 7px;
  margin: 60px 0px 40px 0px;
}

#bar {
  width: 25%;
}

.tdFooter1 {
  padding: 0px 10px 0px 10px;
}

.tdFooter2 {
  padding: 0px 30px 30px 30px;
  color: #666666;
  font-family: "Lato", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.pFooter {
  padding: 30px 0px 0px 0px;
  margin: 0px;
}

#navbar-example3 {
  position: fixed;
}

#prin {
  padding-top: 20px;
  padding-bottom: 20px;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

/* FONTS */
/* @media screen {
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: local('Lato Regular'), local('Lato-Regular'), url(https://fonts.gstatic.com/s/lato/v11/qIIYRU-oROkIk8vfvxw6QvesZW2xOQ-xsNqO47m55DA.woff) format('woff');
  }
  
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    src: local('Lato Bold'), local('Lato-Bold'), url(https://fonts.gstatic.com/s/lato/v11/qdgUG4U09HnJwhYI-uK18wLUuEpTyoUstqEm5AMlJo4.woff) format('woff');
  }
  
  @font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 400;
    src: local('Lato Italic'), local('Lato-Italic'), url(https://fonts.gstatic.com/s/lato/v11/RYyZNoeFgb0l7W3Vu1aSWOvvDin1pK8aKteLpeZ5c0A.woff) format('woff');
  }
  
  @font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 700;
    src: local('Lato Bold Italic'), local('Lato-BoldItalic'), url(https://fonts.gstatic.com/s/lato/v11/HkF_qI1x_noxlxhrhMQYELO3LdcAZYWl9Si6vvxL-qU.woff) format('woff');
  }
} */

.glacial {
  font-family: "Glacial Indifference";
  src: url(https://www.1001fonts.com/download/font/glacial-indifference.regular.otf)
    format("otf");
}

body,
table,
td,
a {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

table,
img {
  -ms-interpolation-mode: bicubic;
}

img {
  border: 0;
  height: auto;
  line-height: 100%;
  outline: none;
  text-decoration: none;
}

table {
  border-collapse: collapse !important;
}

body {
  height: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 100% !important;
}

a[x-apple-data-detectors] {
  color: inherit !important;
  text-decoration: none !important;
  font-size: inherit !important;
  font-family: inherit !important;
  font-weight: inherit !important;
  line-height: inherit !important;
}

.homeImg {
  display: block;
  width: 100%;
  max-width: 500px;
  min-width: 40px;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.footWrapper {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding: 20px 20% 30px 20%;
  color: #666666;
  font-family: "Lato", Helvetica, Arial, sans-serif;
}

.img-container-postSurvey {
  max-width: 80%;
  width: auto;
  /* padding-top: 10%; */
  max-height: 468px;
  background-size: contain;
  background-position: center;
  border-radius: 5px;
}

@media screen and (max-width: 480px) {
  .img1 {
    display: block;
    width: 300px;
    max-width: 400px;
    min-width: 40px;
  }

  .mylogo {
    display: block;
    width: 90px;
    max-width: 200px;
    min-width: 40px;
  }

  h1 {
    font-size: 40px !important;
    line-height: 32px !important;
  }

  .tableWith {
    width: 100%;
    border: 0;
  }

  .wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
  }

  .footWrapper {
    text-align: left;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 20px 20% 30px 20%;
    color: #666666;
    font-family: "Lato", Helvetica, Arial, sans-serif;
  }
  td#tdContact {
    margin: 0px 0px 0px 0px;
  }
  td#tdVideo {
    margin: 0px 0px 0px 0px;
  }
}

/* Para quitar los pulgares dependido la resolucion */
@media screen and (max-width: 1059px) {
  #thumbsDown110 {
    display: none;
  }

  #thumbsUp10 {
    display: none;
  }

  #NumericOptions110 {
    display: unset;
  }
}

@media screen and (min-width: 1059px) {
  #NumericOptions110 {
    display: none;
  }
}

@media screen and (min-width: 759.45px) {
  #NumericOptions15 {
    display: none;
  }
}

@media screen and (max-width: 759.45px) {
  #thumbsDown15 {
    display: none;
  }

  #thumbsUp5 {
    display: none;
  }

  #NumericOptions110 {
    display: unset;
  }

  .wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
  }

  .footWrapper {
    text-align: left;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 20px 20% 30px 20%;
    color: #666666;
    font-family: "Lato", Helvetica, Arial, sans-serif;
  }
}

div[style*="margin: 16px 0;"] {
  margin: 0 !important;
}

.loader {
  width: 148px;
  height: 148px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 25px solid;
  border-color: #0f5b65 #147d8b #199fb1 #1ec1d7;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*-----New-----*/
.card {
  box-shadow: 0px 0px 25px -5px rgb(0 0 0 / 8%);
}

.mw-fit-content {
  max-width: fit-content !important;
}

.text-center.align-top div {
  font-size: 0.5rem !important;
}

.thumb {
  font-size: 0.5rem !important;
}

.btn-outline-dark:not(:has(.mySurveyEmoji)) {
  color: #5f5f5f;
  border-color: #c1c1c1;
  transition: all 300ms;
  padding: 2px 8px;
  border-radius: 8px;
  font-family: "Regular MadeTommy" !important;
}

.btn-outline-dark:hover:not(:has(.mySurveyEmoji)) {
  background-color: #b9bbbd;
  border: 1px solid rgb(168, 168, 168);
  border-radius: 3px;
}

/* #region Estilos Preguntas con emojis */
.btn-outline-dark:has(.mySurveyEmoji) {
  border: none;
  padding: 0px;
}
.btn-outline-dark:hover:has(.mySurveyEmoji) .mySurveyEmoji {
  height: 6em;
  margin-top: -1em;
  background-color: none;
  border: none;
}
.btn-outline-dark:hover:has(.mySurveyEmoji) {
  background-color: #ff000000 !important;
  border: none;
  padding: 0px;
}

.btn-outline-dark .mySurveyEmoji {
  height: 5em;
  padding: 0px;
  margin: 4px;
  transition: all 0.2s ease; /* Transición lenta para todas las propiedades */
  display: inline-block; /* Asegura que el comportamiento del margen sea correcto */
}

.btn-check:checked + .btn-outline-dark:has(.mySurveyEmoji) {
  background-color: #ff000000 !important;
  border: none;
  padding: 0px;
}

/* Pantallas pequeñas */
@media screen and (max-width: 563px) {
  .btn-outline-dark .mySurveyEmoji {
    height: 2.2em;
    padding: 0px;
  }
  .btn-check:checked + .btn-outline-dark:has(.mySurveyEmoji) .mySurveyEmoji {
    outline: none !important;
    box-shadow: none !important;
    height: 2em;
    margin-top: -1em;
  }
}
/* Pantallas Grandes */
@media screen and (min-width: 563px) {
  .btn-check:checked + .btn-outline-dark:has(.mySurveyEmoji) .mySurveyEmoji {
    outline: none !important;
    box-shadow: none !important;
    height: 6em;
    margin-top: -1em;
  }
}
/* #endregion */

.btn-check:active + .btn-outline-dark,
.btn-check:checked + .btn-outline-dark,
.btn-outline-dark.active,
.btn-outline-dark.dropdown-toggle.show,
.btn-outline-dark:active {
  color: rgb(229, 229, 229);
  background-color: #5f5f5f;
  border: 1px solid #858585;
}

/* Quitar el borde y cualquier estilo por defecto en el label */
.form-check-inline label.btn:has(.mySurveyEmoji) {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}

.btn-check:focus + .btn,
.btn:focus {
  box-shadow: 0 0 0 0.18rem rgba(0, 0, 0, 0.25);
}

button.btn {
  transition: all 250ms;
}

button.btn:hover {
  transform: scale(1.05);
  opacity: 0.75;
}

@media (hover: none) {
  button.btn:hover {
    transform: scale(1);
    opacity: 1;
  }
}

/*-----button to userHome-----*/
.userHomeButton:hover {
  color: white;
}

/*-----button to userHome-----*/

/*-----form contact us-----*/

/*-----form contact us-----*/

/*-----New-----*/

.shadow {
  box-shadow: 0 0.9rem 2.5rem rgb(0 0 0 / 11%) !important;
}

.shadow2 {
  box-shadow: 15px 32px 25px -1px rgba(0 0 0 / 15%),
    -29px 20px 20px 1px rgba(0 0 0 / 10%);
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*#region -----Stars-----  */
.star-wrapper {
  top: 50%;
  left: 50%;
  direction: rtl;
}
.star {
  font-size: 2.7em !important;
  color: #666666;
  text-decoration: none;
  transition: transform 1.6s ease-in-out, color 1.6s ease-in-out;
  margin: 4px;
  cursor: pointer;
}
.star:hover {
  color: yellow;
  transform: scale(1.5) rotate(0deg);
}
.s1:hover ~ .star,
.s2:hover ~ .star,
.s3:hover ~ .star,
.s4:hover ~ .star,
.s5:hover ~ .star {
  color: yellow;
}
.wraper {
  position: absolute;
  bottom: 30px;
  right: 50px;
}

/* #endregion -----END Stars-----  */

/*#region -----To choose the language-----  */
.nav-wrapper {
  width: auto;
  margin: auto;
  text-align: center;
}
.sl-nav {
  display: inline;
  color: whitesmoke;
}
.sl-nav ul {
  z-index: 1200;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  display: inline-block;
}
.sl-nav li {
  cursor: pointer;
}
.sl-nav li ul {
  display: none;
}
.sl-nav li:hover ul {
  position: absolute;
  top: 29px;
  right: -30px;
  display: block;
  background: #fff;
  width: 85px;
  padding-top: 0px;
  z-index: 1;
  border-radius: 5px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}
.sl-nav li:hover .triangle {
  display: hidden;
  position: absolute;
  top: 15px;
  right: -20px;
  z-index: 10;
  height: 14px;
  overflow: hidden;
  width: 100px;
  background: transparent;
}
.sl-nav li:hover .triangle:after {
  content: "";
  display: hidden;
  z-index: 20;
  width: 15px;
  transform: rotate(45deg) translateY(0px) translatex(10px);
  height: 15px;
  background: #fff;
  border-radius: 2px 0px 0px 0px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}
.sl-nav li ul li {
  position: relative;
  text-align: left;
  background: transparent;
  padding: 15px 15px;
  padding-bottom: 0;
  z-index: 2;
  font-size: 15px;
  color: #aaa;
}
.sl-nav li ul li:last-of-type {
  padding-bottom: 15px;
}
.sl-nav li ul li span {
  padding-left: 5px;
}
.sl-nav li ul li span:hover,
.sl-nav li ul li span.active {
  color: #146c78;
}

.sl-nv-post {
  display: inline;
  color: whitesmoke;
}

.sl-nv-post ul {
  z-index: 1200;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  display: inline-block;
}

.sl-nv-post li {
  cursor: pointer;
}

.sl-nv-post li ul {
  display: none;
}

.sl-nv-post li:hover ul {
  position: absolute;
  bottom: 29px; /* Mueve el menú hacia arriba */
  right: -8px;
  display: block;
  background: #fff;
  width: 85px;
  padding-top: 0px;
  z-index: 1;
  border-radius: 5px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.sl-nv-post li:hover .triangle {
  display: none; /* Oculta el triángulo si no es necesario */
}

.sl-nv-post li ul li {
  position: relative;
  text-align: left;
  background: transparent;
  padding: 15px 15px;
  padding-bottom: 0;
  z-index: 2;
  font-size: 15px;
  color: #aaa;
}

.sl-nv-post li ul li:last-of-type {
  padding-bottom: 15px;
}

.sl-nv-post li ul li span {
  padding-left: 5px;
}

.sl-nv-post li ul li span:hover,
.sl-nv-post li ul li span.active {
  color: #146c78;
}

.sl-flag {
  display: inline-block;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
  width: 15px;
  height: 15px;
  background: #aaa;
  border-radius: 50%;
  position: relative;
  top: 2px;
  overflow: hidden;
}

/*#endregion -----To choose the language-----  */

ins.adsbygoogle[data-ad-status="unfilled"] {
  display: none !important;
}

/* Se utiliza en varios lados */
.Login {
  position: relative;
  z-index: 40;
}

.loginForm {
  margin: auto;
  width: 60%;
}

.loginFormBtn {
  margin: auto;
  width: 60%;
}

.parent-container {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 993px) {
  .loginForm {
    margin: auto;
    width: 100%;
  }
  .loginFormBtn {
    margin: auto;
    width: 100%;
  }
}

/* This is use in createUser Component */
.signup-content {
  background-color: #f3f2f8;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  padding: 20px 85px;
}

.space {
  overflow: hidden;
  margin-bottom: 20px;
}

.form-submit {
  width: 100%;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  padding: 17px 20px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  border: none;
  background-image: -moz-linear-gradient(to left, #2abdaf, #2abdaf);
  background-image: -ms-linear-gradient(to left, #2abdaf, #2abdaf);
  background-image: -o-linear-gradient(to left, #2abdaf, #2abdaf);
  background-image: -webkit-linear-gradient(to left, #2abdaf, #2abdaf);
  background-image: linear-gradient(to left, #2abdaf, #2abdaf);
}

.form-submit-small {
  width: 100%;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  padding: 17px 20px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  border: none;
}

.form-submit:hover {
  background-image: -moz-linear-gradient(to left, #2abdaf, #74ebd5);
  background-image: -ms-linear-gradient(to left, #2abdaf, #74ebd5);
  background-image: -o-linear-gradient(to left, #2abdaf, #74ebd5);
  background-image: -webkit-linear-gradient(to left, #2abdaf, #74ebd5);
  background-image: linear-gradient(to left, #2abdaf, #74ebd5);
}

/* form-submit when disabled */
.form-submit:disabled {
  background-image: -moz-linear-gradient(to left, #74ebd5, #2abdaf);
  background-image: -ms-linear-gradient(to left, #74ebd5, #2abdaf);
  background-image: -o-linear-gradient(to left, #74ebd5, #2abdaf);
  background-image: -webkit-linear-gradient(to left, #74ebd5, #2abdaf);
  background-image: linear-gradient(to left, #74ebd5, #2abdaf);
  opacity: 0.5;
  cursor: not-allowed;
}

@media screen and (max-width: 768px) {
  .container {
    width: calc(100% - 40px);
    max-width: 100%;
  }
}
@media screen and (max-width: 480px) {
  .signup-content {
    padding: 50px 25px;
  }
}
/* -----This is use in createUser Component------- */

/* Para quitar la etiqueta en las paginas que tienen captchas */
.grecaptcha-badge {
  visibility: hidden;
}

/* Para las imagenes emojis en las respuestas */

.emoji {
  height: 2.1em;
  padding: 0px;
  margin: 2px;
}

@media screen and (max-width: 563px) {
  .emoji {
    height: 1.5em;
    padding: 0px;
  }
}

.btn-emoji {
  padding: 0px;
  margin: 0px;
  border-radius: 11px;
  border: 0px solid #ccc;
  background-color: #fff;
  color: #ccc;
  cursor: pointer;
}

.btn-emoji:hover {
  background-color: #b9bbbd;
  border: 0px solid rgb(168, 168, 168);
  border-radius: 17px;
}

.btn-check:active + .btn-emoji,
.btn-check:checked + .btn-emoji,
.btn-emoji.active,
.btn-emoji.dropdown-toggle.show,
.btn-emoji:active {
  background-color: #b9bbbd9c;
  border: 1px solid #858585;
  border-radius: 11px;
}

/*--------- Animation connection alert ----------*/
.alertConnection {
  margin: 6rem 0px 0px 0rem;
  position: fixed;
  z-index: 1030;
  animation-name: alertConnectionMove;
  animation-duration: 1.4s;
}

@keyframes alertConnectionMove {
  from {
    left: -170px;
  }
  to {
    left: 0px;
  }
}
/*------- END Animation connection alert ---------*/

.stylesCustomers {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='400' height='400' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23404' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23505'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E");
}

.mousePointer {
  cursor: pointer;
}

/* --------------------------------- side menu  --------------------------------*/
.wui-side-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 220px;
  height: 100%;
  backface-visibility: hidden;
  z-index: 3;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: webkit-transform 220ms ease-in-out;
  transition: transform 220ms ease-in-out;
}
.wui-side-menu.open {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.wui-side-menu.open ~ .content .side-menu-trigger {
  display: none;
}
.wui-side-menu.open {
  box-shadow: 2px 0 16px 0 rgba(0, 0, 0, 0.3);
}
.wui-side-menu .header,
.wui-content-header {
  vertical-align: baseline;
}
.wui-side-menu-pin-trigger,
.wui-side-menu-trigger {
  width: 50px;
  height: 50px;
  text-align: center;
  display: inline-block;
  font-size: 18px;
  line-height: 50px;
}

.wui-side-menu .wui-side-menu-pin-trigger {
  display: none;
  float: right;
}
.wui-side-menu .wui-side-menu-pin-trigger i {
  -webkit-transition: all 0.22s ease-out;
  -moz-transition: all 0.22s ease-out;
  -o-transition: all 0.22s ease-out;
  transition: all 0.22s ease-out;
}
.wui-side-menu .wui-side-menu-items {
  overflow-y: auto;
  height: calc(100% - 50px);
}
.wui-side-menu .wui-side-menu-item {
  display: block;
  width: 100%;
  padding: 15px 12px;
  border-left: 5px solid transparent;
}
.wui-side-menu .wui-side-menu-item {
  height: 50px;
}
.wui-side-menu .wui-side-menu-item i.box-ico {
  margin-right: 4px;
}
.wui-side-menu-items {
  padding: 0px 30px;
}

/* overlay */
.wui-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.1);
  opacity: 0.5;
  display: none;
}
.wui-side-menu.open ~ .wui-overlay,
.wui-overlay.active {
  display: block;
}

/* content */
.wui-content {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transition: all 0.22s ease-out;
  -moz-transition: all 0.22s ease-out;
  -o-transition: all 0.22s ease-out;
  transition: all 0.22s ease-out;
  padding: 0 10px;
}
.wui-content .wui-side-menu-trigger {
  margin-left: -10px;
}
@media only screen and (min-width: 768px) {
  .wui-side-menu .wui-side-menu-pin-trigger {
    display: inline-block;
  }
  .wui-side-menu.open {
    box-shadow: initial;
  }
  .wui-side-menu.open ~ .wui-overlay {
    display: none;
  }
  .wui-side-menu.open ~ .wui-content .wui-side-menu-trigger {
    display: none;
  }
  .wui-side-menu.open:not(.pinned) ~ .wui-overlay {
    display: block;
  }
  .wui-side-menu.open:not(.pinned) {
    box-shadow: 2px 0 16px 0 rgba(0, 0, 0, 0.3);
  }
  .wui-side-menu.open.pinned ~ .wui-content {
    left: 220px;
  }
}
ul.wui-side-menu-items {
  list-style: none;
  padding: 0;
}

.wui-side-menu {
  background-color: #f2f1f7;
  color: black;
  font-family: "Regular MadeTommy";
}
.wui-side-menu .wui-side-menu-trigger:hover,
.wui-side-menu-item:hover,
.wui-side-menu-pin-trigger:hover {
  color: #fff;
  background-color: rgb(98, 92, 151);
}

.wui-side-menu a {
  color: black;
  text-decoration: none;
}
.wui-side-menu .wui-side-menu-item.active {
  border-left-color: #158439;
  color: #158439;
}
.wui-content a {
  color: #000;
}

.pes {
  margin: 10px 0px;
  background-color: rgba(0, 0, 0, 0.3);
}

/* -------------------------------------------------------------------------------*/

#chart {
  display: block; /* iframes are inline by default */
  background: #000;
  border: none; /* Reset default border */
  height: 100vh; /* Viewport-relative units */
  width: 100%;
}

:root {
  --bg-body: rgba(32, 32, 32, 1);
  --bg-body-image: radial-gradient(
    30% 90% ellipse at bottom center,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 1) 100%
  );
  --bg-body-hover: rgba(40, 40, 40, 1);
  --bg-button: rgba(255, 255, 255, 0);
  --bg-button-hover: rgba(255, 255, 255, 0.05);
  --bg-button-active: rgba(255, 255, 255, 1);
  --bg-mask: rgba(255, 255, 255, 0.5);
  --bg-mask-hover: rgb(231, 213, 48);
  --border-button: rgba(255, 255, 255, 0.2);
  --border-button-hover: rgb(183, 218, 60);
  --color-button: rgba(255, 255, 255, 0.6);
  --color-button-hover: rgba(255, 255, 255, 1);
  --color-button-active: var(--body-bg);
  --font-button: "Varela Round", sans-serif;
  --shadow-button-hover: 0 0 0.3125rem rgba(255, 255, 255, 0.8);
}

.buttonPlan {
  background-color: var(--bg-button);
  border: 0.125rem solid var(--border-button);
  cursor: pointer;
  letter-spacing: 0.2125rem;
  line-height: 1;
  overflow: hidden;
  padding: 1rem 1.175rem;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  transition: background-color 0.2s cubic-bezier(0.19, 1, 0.22, 1),
    border 1s cubic-bezier(0.19, 1, 0.22, 1),
    color 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  user-select: none;
}

.buttonPlan b {
  color: var(--color-button);
  font-family: var(--font-button);
  text-decoration: none;
  white-space: nowrap;
}

.buttonPlan .mask {
  background-color: var(--bg-mask);
  height: 6.25rem;
  position: absolute;
  transform: translate3d(-120%, -50px, 0) rotate3d(0, 0, 1, 45deg);
  transition: all 1.1s cubic-bezier(0.19, 1, 0.22, 1);
  width: 12.5rem;
}

.buttonPlan .shift {
  display: inline-block;
  transition: all 1.1s cubic-bezier(0.19, 1, 0.22, 1);
  vertical-align: text-top;
}

.buttonPlan:hover {
  background-color: var(--bg-button-hover);
  border-color: var(--border-button-hover);
  box-shadow: var(--shadow-button-hover);
}

.buttonPlan:hover b {
  color: var(--color-button-hover);
}

.buttonPlan:hover .mask {
  background-color: var(--bg-mask-hover);
  transform: translate3d(120%, -100px, 0) rotate3d(0, 0, 1, 90deg);
}

@media screen and (min-width: 1500px) {
  .buttonPlan:hover .mask {
    transform: translate3d(220%, -50px, 0) rotate3d(0, 0, 1, 90deg);
  }
}

@media screen and (min-width: 1800px) {
  .buttonPlan:hover .mask {
    transform: translate3d(420%, -50px, 0) rotate3d(0, 0, 1, 90deg);
  }
}

@media screen and (min-width: 2000px) {
  .buttonPlan:hover .mask {
    transform: translate3d(630%, -50px, 0) rotate3d(0, 0, 1, 90deg);
  }
}

.button:hover .shift {
  transform: translateX(0.3125rem);
}

.buttonPlan:active {
  background-color: var(--bg-button-active);
}

.buttonPlan:active a {
  color: var(--color-button-active);
}

.dashbtn {
  margin-left: 10px;
  margin-top: 5px;
}

@media screen and (max-width: 768px) {
  .close {
    margin: 10px auto 0px auto;
  }
}

/* SETTINGS COMPONENT */

.ui-w-80 {
  width: 80px !important;
  height: auto;
}

.btn-default {
  border-color: rgba(24, 28, 33, 0.1);
  background: rgba(0, 0, 0, 0);
  color: #4e5155;
}

label.btn {
  margin-bottom: 0;
}

.btn-outline-primary {
  border-color: #26b4ff;
  background: transparent;
  color: #26b4ff;
}

.btn {
  cursor: pointer;
}

.text-light {
  color: #babbbc !important;
}

.btn-facebook {
  border-color: rgba(0, 0, 0, 0);
  background: #3b5998;
  color: #fff;
}

.btn-instagram {
  border-color: rgba(0, 0, 0, 0);
  background: #000;
  color: #fff;
}

.card {
  background-clip: padding-box;
  box-shadow: 0 1px 4px rgba(24, 28, 33, 0.012);
}

.row-bordered {
  overflow: hidden;
}

.account-settings-fileinput {
  position: absolute;
  visibility: hidden;
  width: 1px;
  height: 1px;
  opacity: 0;
}
.account-settings-links .list-group-item.active {
  font-weight: bold !important;
}
html:not(.dark-style) .account-settings-links .list-group-item.active {
  background: transparent !important;
}
.account-settings-multiselect ~ .select2-container {
  width: 100% !important;
}
.light-style .account-settings-links .list-group-item {
  padding: 0.85rem 1.5rem;
  border-color: rgba(24, 28, 33, 0.03) !important;
}
.light-style .account-settings-links .list-group-item.active {
  color: #4e5155 !important;
}
.material-style .account-settings-links .list-group-item {
  padding: 0.85rem 1.5rem;
  border-color: rgba(24, 28, 33, 0.03) !important;
}
.material-style .account-settings-links .list-group-item.active {
  color: #4e5155 !important;
}
.dark-style .account-settings-links .list-group-item {
  padding: 0.85rem 1.5rem;
  border-color: rgba(255, 255, 255, 0.03) !important;
}
.dark-style .account-settings-links .list-group-item.active {
  color: #fff !important;
}
.light-style .account-settings-links .list-group-item.active {
  color: #4e5155 !important;
}
.light-style .account-settings-links .list-group-item {
  padding: 0.85rem 1.5rem;
  border-color: rgba(24, 28, 33, 0.03) !important;
}

/* -------- SETTINGS COMPONENT ---------- */

.demoButton {
  width: 45%;
}

@media screen and (max-width: 768px) {
  .demoButton {
    width: 100%;
    margin-top: 10px !important;
  }
}

.centerRecaptcha {
  display: flex;
  justify-content: center;
}

.editQuestionModal {
  width: 70%;
}

.editButton {
  min-height: 20px !important;
  height: 30px !important;
  width: 30px !important;
}

.editButtonIcon {
  font-size: 20px !important;
}

.questionType {
  margin-top: -5px;
}

.questionTypeFromControl {
  float: right;
}

.orderQuestion {
  min-width: 70px;
  margin-right: 10px !important;
}

@media screen and (max-width: 768px) {
  .questionType {
    margin-top: 9px;
  }
  .questionTypeFromControl {
    float: left;
  }
}

@media screen and (max-width: 500px) {
  .editQuestionModal {
    width: 95%;
  }
}

#btn-back-to-top {
  position: fixed;
  bottom: 20px;
  left: 20px;
  display: block;
  border-radius: 50px;
  z-index: 100;
}

.cardIcon {
  width: 44px !important;
  height: 44px !important;
  margin-top: 5px;
}

@media screen and (max-width: 900px) {
  .cardIcon {
    width: 30px !important;
    height: 30px !important;
    margin-top: 10px;
  }
}

@media screen and (max-width: 768px) {
  .cardIcon {
    width: 44px !important;
    height: 44px !important;
    margin-top: 5px;
  }
}

.minHeightTab {
  min-height: 48px !important;
}

.valuesCard {
  /* Add shadows */
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  transition: 0.3s;
}

.inner-element {
  width: 100%; /* Set the width of the inner element to 100% */
  padding: 10px;
  box-sizing: border-box; /* Include padding in the total width */
}

.dashboardTitle {
  margin-left: 15%;
}

.filterRange {
  margin: 0;
  min-width: 100;
  float: right;
}

@media screen and (max-width: 510px) {
  .filterRange {
    margin: 0;
    min-width: 100;
    float: none;
  }
  .dashboardTitle {
    margin-left: 0%;
  }
}

#app-navbar {
  background-color: #726ca8;
}

/*------ CircleColorPicker ------*/
.CircleColorPicker {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 32px;
  height: 30px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.CircleColorPicker::-webkit-color-swatch {
  border-radius: 15px;
  border: none;
}
.CircleColorPicker::-moz-color-swatch {
  border-radius: 15px;
  border: none;
}

.titleInput {
  border: none;
  outline: none;
  width: 100%;
  height: 50px;
  overflow: hidden;
  resize: none;
}

.titleInputSmall {
  border: none;
  outline: none;
  width: 100%;
  height: 28px;
  overflow: hidden;
  resize: none;
}

.sourceInput {
  border: none;
  outline: none;
  background: transparent; /* Removes the white background */
  border-bottom: 1px solid whitesmoke; /* Adds only the bottom border */
  /* width: 100%; */
  height: 28px;
  overflow: hidden;
  resize: none;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

.animated-hourglass {
  animation: rotate 0.5s infinite linear;
}

.shareicon {
  color: gray;
  transition: color 0.3s ease; /* Para hacer una transición suave */
}

.shareicon:hover {
  color: black; /* Cambia a azul cuando se pasa el ratón por encima */
}

@font-face {
  font-family: "Regular MadeTommy";
  src: url("../public/fonts/MADE\ TOMMY\ Regular_PERSONAL\ USE.otf")
    format("truetype");
}
@font-face {
  font-family: "Medium MadeTommy";
  src: url("../public/fonts/MADE\ TOMMY\ Medium_PERSONAL\ USE.otf")
    format("truetype");
}
@font-face {
  font-family: "Light MadeTommy";
  src: url("../public/fonts/MADE\ TOMMY\ Light_PERSONAL\ USE.otf")
    format("truetype");
}
@font-face {
  font-family: "Thin MadeTommy";
  src: url("../public/fonts/MADE\ TOMMY\ Thin_PERSONAL\ USE.otf")
    format("truetype");
}
@font-face {
  font-family: "Bold MadeTommy";
  src: url("../public/fonts/MADE\ TOMMY\ Bold_PERSONAL\ USE.otf")
    format("truetype");
}
@font-face {
  font-family: "Black MadeTommy";
  src: url("../public/fonts/MADE\ TOMMY\ Black_PERSONAL\ USE.otf")
    format("truetype");
}

h2.MadeTommy {
  font-family: "Regular MadeTommy";
}

h4.MadeTommy {
  font-family: "Light MadeTommy";
}

.RegularMadeTommy {
  font-family: "Regular MadeTommy" !important;
}

.MediumMadeTommy {
  font-family: "Medium MadeTommy";
}

.BoldMadeTommy {
  font-family: "Bold MadeTommy" !important;
}
/* #regionEste el del input de autocomplete */
/* Este es el texto de dentro */
.LightMadeTommy .MuiInputBase-input {
  font-family: "Light MadeTommy" !important;
}
.RegularMadeTommy .MuiInputBase-input {
  font-family: "Regular MadeTommy" !important;
}
.MediumMadeTommy .MuiInputBase-input {
  font-family: "Medium MadeTommy" !important;
}
/* #endregion */

/*#region Este las parte de arriba del outlined(label) */
.LightMadeTommy .MuiInputLabel-root {
  font-family: "Light MadeTommy" !important;
}
.RegularMadeTommy .MuiInputLabel-root {
  font-family: "Regular MadeTommy" !important;
}
.MediumMadeTommy .MuiInputLabel-root {
  font-family: "Medium MadeTommy" !important;
}

/* #endregion */

.BlackMadeTommy {
  font-family: "Black MadeTommy";
}

.LightMadeTommy {
  font-family: "Light MadeTommy" !important;
}

button {
  font-family: "Regular MadeTommy" !important;
}

.mysurveybtn {
  font-family: "Medium MadeTommy" !important;
  width: 30%;
  margin-top: 3rem;
  min-width: 3rem;
  border-radius: 10px;
}

@media screen and (max-width: 768px) {
  .mysurveybtn {
    width: 80%;
  }
}

/* Animación para entrar desde la derecha */
.slide-in-right {
  animation: slideInRight 0.5s forwards;
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Animación para salir hacia la derecha */
.slide-out-right {
  animation: slideOutRight 0.5s forwards;
}

@keyframes slideOutRight {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}

/* Animación para entrar desde la izquierda */
.slide-in-left {
  animation: slideInLeft 0.5s forwards;
}

@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Animación para salir hacia la izquierda */
.slide-out-left {
  animation: slideOutLeft 0.5s forwards;
}

@keyframes slideOutLeft {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}
.divmysurveybtn {
  position: fixed; /* Fija el botón en la pantalla */
  bottom: 0; /* Se coloca automáticamente en la parte inferior */
  width: 100%; /* Ocupa todo el ancho de la pantalla */
  z-index: 1000; /* Asegura que esté sobre otros elementos */
  text-align: center; /* Centra el contenido dentro del contenedor */
  margin-bottom: 20px;
}



/* #region ----- DateRangePicker/Dashboard ----- */
.filter-date-range {
  display: flex;
  align-items: center;
  gap: 8px;
  border: 2px solid #bdbdbd;
  border-radius: 8px;
  padding: 4px 8px;
  margin-left: auto; /* Empuja todo hacia la derecha */
  width: fit-content;
}

.date-range-display {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 18px;
  font-family: "Regular MadeTommy";
  color: #555;
}

.divider {
  width: 2px;
  height: 24px;
  background-color: #bdbdbd;
}

/* Select más compacto */
.date-range-select {
  display: flex;
  align-items: center;
  min-width: auto;
  padding: 0;
}

.date-range-select .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.date-range-select .MuiSelect-select {
  padding: 0 8px; /* Espaciado interno */
  text-align: right;
  font-size: 18px;
  font-family: "Regular MadeTommy";
}

@media (max-width: 648px) {
  .filter-date-range {
      margin-left: 0; /* Lo regresa a la izquierda */
  }
  .date-range-display {
    font-size: 12px;
  }
  .date-range-select .MuiSelect-select {
    font-size: 12px;
  }
}


/* #endregion ----- DateRangePicker -----  */
